<template>
    <div>
        <el-table :data="brandList"  style="width: 100%; margin-top: 20px" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                  @sort-change="sortByEvent"
                  :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column label="排名" align="center" width="60">
                <template slot-scope="scope">
                    <div class="ranking" v-if="scope.row.sort === 1 || scope.row.sort === 2 || scope.row.sort === 3">{{scope.row.sort}}</div>
                    <div v-else>{{scope.row.sort}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="brand_name" label="品牌信息" min-width="200"></el-table-column>
            <el-table-column prop="turnoverIndex" align="center" label="金额指数" sortable="custom"></el-table-column>
            <el-table-column prop="volumeIndex" align="center" label="单量指数" sortable="custom"></el-table-column>
            <el-table-column prop="click_num" align="center" label="点击指数" sortable="custom"></el-table-column>
            <el-table-column prop="transaction_conversion" align="center" label="转化指数" sortable="custom"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue-light" @click="toDetail(scope.row)">详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="operation-pagination" style="margin: 20px 0 0;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import _ from "underscore";
    import {tranPurchaseBrandTop} from '@/utils/apis'
    export default {
        data() {
            return {
                brandList: [],
                listPages: {
                    currentPageNum:1,
                    eachPageNum: 10,
                    total: 0,
                },
                brandListTmp: [],
                brandListData: [],
                sortName: '',
                sortType: '',
            }
        },
        mounted() {
            this.getBrandList()
        },
        methods: {
            sortByEvent(val) {
                this.sortName = val.prop;
                this.sortType = val.order==='ascending' ? 'asc' : 'desc';
                this.getBrandList()
            },
            // 获取品牌列表
            getBrandList() {
                let param ={
                    paging:1,
                    page:this.listPages.currentPageNum,
                    pageSize:this.listPages.eachPageNum,
                    sort_name: this.sortName,
                    sort_type: this.sortType,
                }
                if (this.$route.query.op_id) {
                    param.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                tranPurchaseBrandTop(param).then((res) => {
                    this.brandList = res.data.list
                    this.listPages.total = res.data.total
                }).catch((err)  => {
                    console.log(err);
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getBrandList()
            },
            // 详情
            toDetail(row) {
                if (this.$route.query.op_id) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/brandDetail',
                        query: {
                            op_id: this.$route.query.op_id,
                            exam_module_id:this.$route.query.exam_module_id,
                            course_id:this.$route.query.course_id,
                            brand:row.brand,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/procurement/BrandDetail',
                        query: {
                            exam_module_id:this.$route.query.exam_module_id,
                            brand:row.brand,
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-table {
        overflow: auto;
        border: 1px solid #eee;
        .el-table__body-wrapper {
            .el-table__row {
                .ranking {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-weight: 500;
                    background-size: cover;
                    display: inline-block;
                }
                &:first-child {
                    .ranking {
                        background: url("../../../assets/images/student/num-001.png") no-repeat center;
                    }
                }
                &:nth-of-type(2) {
                    .ranking {
                        background: url("../../../assets/images/student/num-002.png") no-repeat center;
                    }
                }
                &:nth-of-type(3) {
                    .ranking {
                        background: url("../../../assets/images/student/num-003.png") no-repeat center;
                    }
                }

            }
        }
    }
    .operation-pagination {
        padding: 0;
        margin: 30px 0;
        text-align: right;
        .el-pager {
            li.active {
                color: #FD4446;
            }
        }
        .el-pagination__sizes {
            .el-input__inner {
                color: #333;
            }
        }
        .el-pagination__total {
            color: #333;
        }
        .el-pagination__jump {
            color: #333;
        }
    }
    .link-blue-light {
        &.el-link--default {
            color: #1E63F1;
            &:hover {
                color: #6191f5;
            }
            &.is-disabled {
                &:hover {
                    color: #C0C4CC;
                }
            }
        }
    }
</style>