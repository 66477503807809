<template>
    <div class="CommodityList-content">
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item>采购管理</el-breadcrumb-item>
            <el-breadcrumb-item>数据分析</el-breadcrumb-item>
            <el-breadcrumb-item>商品榜单</el-breadcrumb-item>
            <el-breadcrumb-item>{{tabName}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-title">{{tabName}}</div>
        <div class="goodsPurchase-container">
            <el-tabs v-model="categoryActive" @tab-click="handleClick" class="commodityTab">
                <el-tab-pane label="品牌榜单" name="brandList">
                    <BrandList />
                </el-tab-pane>
                <el-tab-pane label="交易榜单" name="tradingList">
                    <TradingList :tabName="tabName" />
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import BrandList from '../../../../components/student/commodityList/BrandList'
    import TradingList from '../../../../components/student/commodityList/TradingList'
    export default {
        name: "CommodityList",
        components: {
            TradingList,
            BrandList
        },
        data() {
            return {
                categoryActive: 'brandList',
                tabName: '品牌榜单',
            }
        },
        methods: {
            handleClick(tab, event) {
                // console.log(tab, event);
                if (tab.name === 'brandList') {
                    this.tabName = '品牌榜单'
                } else if (tab.name === 'tradingList') {
                    this.tabName = '交易榜单'
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .page-title {
        font-size: 20px;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 0 20px 20px;
        min-height: calc(100vh - 190px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    ::v-deep .commodityTab {
        .el-tabs__header {
            margin-bottom: 0;
            .el-tabs__nav-wrap {
                &:after {
                    content: none;
                }
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                color: #666;
                font-weight: 400;
                &.is-active, &:hover {
                    color: #FD4446;
                }
            }
            .el-tabs__active-bar {
                background-color: #FD4446;
            }
        }
    }
</style>